import React, { useContext, useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs';
import { Card, Text, Avatar, ScrollArea, Textarea, Button, Loader } from '@mantine/core';
import AuthContext from '../../contexts/AuthContext';
import { getResponseAI } from '../../apis/user.api';
import uniBot from '../../assets/MascotteAnimée.gif'
import { formaterTextResponseAI } from '../../utils/utilsApi';
import io from 'socket.io-client';

const socket = io("https://socket.unitix.io");

const CardMessage = ({ message, isOutgoing, author, date }) => {

    const appContext = useContext(AuthContext);
    const fullName = appContext.username;
    const formattedExemple = formaterTextResponseAI(message);

    return (
        <Card
            shadow="sm"
            p="xl"
            component="a"
            style={{
                marginBottom: '10px',
                background: fullName === author ? "linear-gradient(120deg, rgba(0,180,255,1) 0%, rgba(0,155,255,1) 100%)" : author === "AI" ? "white" : "#fff",
                color: fullName === author ? "white" : author === "AI" ? "black" : "black",
                marginLeft: fullName === author ? "65px" : "0",
                marginRight: fullName !== author ? "65px" : "0",
                border: "1px solid rgb(233 233 233)"
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyItems: 'center',
                    gap: '3px',
                }}
            >
                {
                    author === "AI" ? <img src={uniBot} width="70px" /> : <Avatar radius="xl" />
                }

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Text weight={500} size="lg">
                        {author}
                    </Text>
                    <Text weight={300} size="sm">
                        {dayjs(date).format('DD/MM/YYYY HH:mm')}
                    </Text>
                </div>
            </div>

            <div color={fullName === author ? "white" : author === "AI" ? "black" : "black"} dangerouslySetInnerHTML={{ __html: formattedExemple }}>

            </div>
        </Card>
    );
};

export const Chat = ({ username, room, AIEnable }) => {
    const [currentMessage, setCurrentMessage] = useState("");
    const [messageList, setMessageList] = useState([]);
    const appContext = useContext(AuthContext);

    const [conversation, setconversation] = useState([{ "role": "system", "content": "Tu es un assistant téchnique" }])
    const [answerAI, setAnswerAI] = useState(true);
    const [visible, setVisible] = useState(false);
    const viewport = useRef(null);
    console.log(room)

    const sendMessage = async (data) => {
        if (currentMessage !== "") {

            const messageData = {
                room: room,
                author: username,
                message: currentMessage
            }
            socket.emit("send_message", messageData);
            setMessageList((list) => [...list, messageData]);

            setCurrentMessage("");
            conversation.push({ "role": "user", "content": currentMessage })
            if (answerAI) {
                if (AIEnable === true) {
                    await getResponseAI(conversation).then((response) => {
                        setVisible(true);
                        const messageAI = {
                            room: room,
                            author: "AI",
                            message: response
                        }
                        conversation.push({ "role": "system", "content": messageAI.message })
                        return messageAI;
                    }).then((messageAI) => {
                        socket.emit("send_message", messageAI);
                        setMessageList((list) => [...list, messageAI]);
                        setVisible(false);
                    })
                }

            }
        }

        const scrollToBottom = () => viewport.current.scrollTo({ top: viewport.current.scrollHeight, behavior: 'smooth' });
        scrollToBottom()

    }

    const joinRoom = async () => {
        if (appContext?.username !== "" && room !== null) {
            socket.emit("join_room", room);
        } else {
            Promise.reject("Error");
        }
    }

    let listMessages = [];
    useEffect(() => {
        joinRoom();
        socket.on("get_messages", (data) => {
            data.map((message) => {
                const messageData = {
                    room: message.room_id,
                    author: message.author,
                    message: message.message,
                    date: message.date
                }
                listMessages.push(messageData);
            })
            setMessageList([...listMessages]);
        });
        socket.on("receive_message", (data) => {
            const messageData = {
                room: data.room,
                author: data.author,
                message: data.message,
                date: data.date
            }
            listMessages.push(messageData);
            setMessageList([...listMessages]);

        }
        )

    }, [])

    useEffect(() => {
        listMessages.map((message) => {

            if (message.author === "AI") {
                conversation.push({ "role": "system", "content": message.message })
            }
            if (message.author === username) {
                conversation.push({ "role": "user", "content": message.message })
            }
        })
    }, [listMessages])


    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                height: '100vh',
            }}
        >
            <Card
                shadow="sm"
                p="xl"
                component="a"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                }}
            >
                <ScrollArea type="auto" style={{ height: '55vh' }} viewportRef={viewport}>
                    {messageList.map((messageContent) => {
                        return (<CardMessage message={messageContent.message} isOutgoing="isOutgoing" author={messageContent.author} date={messageContent.date} />)
                    })}
                    {visible === true ? <Loader variant="dots" /> : null}
                </ScrollArea>
            </Card>
            <Card shadow="sm">
                <Text weight={200} size="sm">
                    Message
                </Text>
                <Textarea value={currentMessage} onChange={(e) => {
                    setCurrentMessage(e.target.value)
                }} />
                <Button fullWidth mt="sm" onClick={() => sendMessage()} >
                    Envoyer
                </Button>
            </Card>
        </div >
    );
};