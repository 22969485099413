import { useState } from "react";
import { getResponseAI } from "../apis/user.api";
import { OutputBlockData, OutputData } from "@editorjs/editorjs";


function useAI() {
  // Déclaration du state conversation qui contient les échanges entre l'utilisateur et l'assistant
  const [conversation, setConversation] = useState([
    {
      role: "user", content: `Utilise le format JSON ci-dessus pour envoyer ta question ou ton message. Ensuite, je vais répondre sous forme de bloc du package EditorJS. Dans ce prompt, la premiere partie est définie comme un paragraphe de la question que poser, et la deuxiéme partie est définie comme une liste à  puces avec trois points. Tu peux ajouter ou supprimer des éléments de la liste à puces en modifiant les éléments de la propriété "items".
    Réponds toujours sous le format JSON et fini les fermetures des balises du json tu devras y ajouter les solutions que tu proposes selon la question sauf si tu ne comprends pas la question tu devras répondre "erreur dans votre question".`},
  ]);

  // Déclaration du state outputBlock qui contient les blocs de sortie de l'assistant afin de les afficher avec le package EditorJS
  const [outputBlock, setOutputBlock] = useState<OutputBlockData[]>();

  // Fonction qui permet de demander à l'IA une question
  const askAI = async (question: string) => {
    // Ajoute la question de l'utilisateur à la conversation
    conversation.push({ role: "user", content: question });

    // Appelle l'API pour obtenir la réponse de l'IA
    await getResponseAI(conversation)
      .then((response: any) => {
        let data: OutputData = JSON.parse(response);
        // Ajoute la réponse de l'IA à la conversation
        conversation.push({ role: "assistant", content: response.data });
        // Met à jour les blocs de sortie de l'assistant
        setOutputBlock(data.blocks);
        // Supprime les deux derniers messages de la conversation pour relancer une nouvelle conversation
        // A savoir qu'a chaque relance(askAI) de conversation, le premier message est toujours le prompt afin de diriger la réponse
        conversation.pop();
        conversation.pop();
      })
  };

  // Traiter la réponse de l'IA

  return {
    askAI,
    outputBlock
  };
}

export default useAI;
