import React, { memo, useEffect, useRef, useState } from "react";
import EditorJS, {  OutputBlockData, OutputData } from "@editorjs/editorjs";
import { EDITOR_TOOLS } from "./EditorTools";

//props
type Props = {
    data?: OutputData;
    onChange(val: OutputData): void;
    blocks?: OutputBlockData<string, any>[];
    holder: string;
    readonly: boolean;
  };

  export enum LogLevels {
    VERBOSE = 'VERBOSE',
    INFO = 'INFO',
    WARN = 'WARN',
    ERROR = 'ERROR',
  }

function EditorBlock({ onChange, holder, data, blocks, readonly }: Props) {
  
    //add a reference to editor
  const ref = useRef<EditorJS>();
     //initialize editorjs
    //initialize editor if we don't have a reference
    if (!ref.current) {
      
      const editor = new EditorJS({
        holder: holder,
        tools: EDITOR_TOOLS,
        data: blocks ?{time: 1552744582955, blocks: blocks , version: "2.12.4"} : data,
        logLevel: LogLevels.ERROR,
        readOnly: readonly,
        async onChange(api, event) {
          const data = await api.saver.save();
          onChange(data); 
        }
      });
      
      ref.current = editor;
    }
   
   

  return <div id={holder} />;
}


export default memo(EditorBlock);