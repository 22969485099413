import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { NavbarMinimal } from './NavbarMinimal'
import { SpotlightAction, SpotlightProvider, closeSpotlight } from '@mantine/spotlight'
import AuthContext from '../contexts/AuthContext';
import { IconSearch } from '@tabler/icons-react';
import { Box, Button, Group, LoadingOverlay, Modal, Text} from '@mantine/core'
import Editor from '../components/Editor';
import EditorBlock from '../components/Editor/Editor';
import useAI from '../hooks/useAI';
import { OutputBlockData, OutputData } from '@editorjs/editorjs';

interface Props {
    children: ReactNode
}

function Main(props: Props) {
    let actions: SpotlightAction[] = [];
    const app = useContext(AuthContext);
    const { children } = props
    const [opened, setOpened] = useState(false);
    const [openedPreviewEditor, setPreviewEditor] = useState(false);
    const { askAI, outputBlock } = useAI();

    const [data, setData] = useState({});
    const [demand, setDemand] = useState({});
    const [query, setQuery] = useState('');
    const listDemands = app?.demands
    const [dataBlocks, setDataBlocks] = useState<OutputBlockData[]>([]);
    const [dataPreview, setDataPreview] = useState<OutputData>();

    const [visible, setVisible] = useState(false);

    if (listDemands) {
        listDemands.map(((value: any) => {
            let action = {
                title: value.title,
                description: value.category,
                group: 'Demandes',
                onTrigger: () => {
                    setOpened(!opened)
                    setData(JSON.parse(value.name))
                    setDemand(value);
                },
            }
            actions.push(action)

        }))
    }
    useEffect(() => {
        if (outputBlock) {
            console.log(outputBlock)
            setDataBlocks(outputBlock);
        }
    }, [outputBlock]);


    function ActionsWrapper({ children }: { children: React.ReactNode }) {
        return (
            <div>
                {children}

                {query.length > 1 && (
                    <>
                        <Group
                            position="apart"
                            px={15}
                            py="xl"
                        >
                             <Text size="lg" color="dimmed">
                                Interroger l'IA
                            </Text>
                            <Button variant="light" onClick={() => 
                                {
                                    closeSpotlight();
                                    setVisible(true);
                                    askAI(query).then(() => {
                                        setVisible(false);
                                        setPreviewEditor(!openedPreviewEditor);
                                        setQuery('');
                                    });
                                }
                            }>
                                <IconSearch size={18} style={{ display: 'block', opacity: 0.5 }} />
                            </Button>

                        </Group>
                    </>
                )}

            </div>
        );
    }

    return (
        <SpotlightProvider
            shortcut={['mod + P', 'mod + K', '/']}
            actions={actions}
            onQueryChange={(value) => { setQuery(value) }}
            actionsWrapperComponent={ActionsWrapper}
            limit={5}
        >
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
               fullScreen
            >
                <Editor data={data} demand={demand} modalClose={opened} setOpened={setOpened} />

            </Modal>
            <Modal
                opened={openedPreviewEditor}
                onClose={() => setPreviewEditor(false)}
                title="Réponse de l'ia"
                centered
            >
                <EditorBlock data={dataPreview} blocks={dataBlocks} onChange={setDataPreview} holder='preview-editor' readonly={true} />

            </Modal>
            <div style={{ display: "flex", gap: "30px" }}>
                <NavbarMinimal />
                <Box maw="100%" pos="relative">
                    <LoadingOverlay visible={visible} overlayBlur={2} />
                    <div className="body-dashboard" >
                        {children}
                    </div>
                </Box>
            </div>

        </SpotlightProvider>
    )
}

export default Main