import { Button, Group, Input, Modal } from '@mantine/core';
import { useSpotlight } from '@mantine/spotlight';
import type { SpotlightAction } from '@mantine/spotlight';
import { IconHome, IconDashboard, IconFileText, IconSearch } from '@tabler/icons-react';
import i18n from '../../i18n';
import Editor from '../Editor';
import { useState } from 'react';



export function SearchBarDemo() {
    const spotlight = useSpotlight();
    return (
        <Input
        placeholder={i18n.t("searchRequest")}
        style={{
            width: "500px"
        }}
        onClick={() => spotlight.openSpotlight()}
        rightSection={
            <IconSearch size={18} style={{ display: 'block', opacity: 0.5 }} />
        }
    />
    );
}
