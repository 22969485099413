import React, { useEffect, useState } from 'react'
import { CardProfile } from '../../components/CardProfile/CardProfile'
import {
    TextInput,
    Paper,
    Title,
    Button, Grid, SimpleGrid, useMantineTheme
} from '@mantine/core';
import { motion } from "framer-motion"
import { getProfile, updateProfile } from '../../apis/user.api';
import i18n from '../../i18n';
import { useForm } from '@mantine/form';

function Account() {
    const [user, setUser] = useState<Profile | undefined>(undefined);
    useEffect(() => {
        getProfile().then((response) => {
            const { data }: any = response
            setUser(data);
        })
    }, [])
    const form = useForm({
        initialValues: { firstname: user?.firstname, lastname: user?.lastname, phone: user?.phone, address: user?.address, city: user?.city },
        // functions will be used to validate values at corresponding key

    });


    return (
        <div >
            <Title size="3.5em" style={{
                fontWeight: 600,
                fontFamily: "Space Grotesk"
            }}>{i18n.t("ManageAccount")}</Title>
            <SimpleGrid cols={2} spacing="md" breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
                <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.2 }}
                >
                    <CardProfile image="https://cdn.pixabay.com/photo/2016/03/19/12/57/wallpaper-1266599_960_720.png" avatar="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" name={user?.firstname + " " + user?.lastname} />
                </motion.div>

                <Grid style={{
                    margin: "8px"
                }} >

                    <Grid.Col>
                        <motion.div
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.7 }}
                        >
                            <Paper withBorder shadow="md" p={30} radius="md" style={{
                                border: "1px solid orange"
                            }}>
                                <Title
                                    align="left"
                                    style={{
                                        fontWeight: 900,
                                        fontFamily: "Space Grotesk"
                                    }}
                                >
                                    {i18n.t("personalInformation")}
                                </Title>
                                <form onSubmit={form.onSubmit((values) => {

                                    const phone = values.phone
                                    const firstname = values.firstname
                                    const lastname = values.lastname
                                    const address = values.address
                                    const city = values.city
                                    updateProfile({ firstname, lastname, phone, city, address });
                                })}>

                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}>
                                        <TextInput {...form.getInputProps('firstname')} label={i18n.t("Prénom")} defaultValue={user?.firstname} name='Fristname' />
                                        <TextInput {...form.getInputProps('lastname')} label={i18n.t("LastName")} name='Lastname' defaultValue={user?.lastname} />
                                    </div>

                                    <TextInput {...form.getInputProps('phone')} label={i18n.t("NumeroPortable")} name="Phone" defaultValue={user?.phone} />
                                    {/*<TextInput label={i18n.t("Pays")} placeholder="Country" />*/}
                                    <TextInput {...form.getInputProps('address')} label={i18n.t("Adresse")} defaultValue={user?.address} />

                                    <TextInput {...form.getInputProps('city')} label={i18n.t("Ville")} defaultValue={user?.city} />
                                    <Button fullWidth type="submit" sx={(theme) => ({
                                        marginTop: "20px",
                                        backgroundColor: theme.colors.orange[6], '&:hover': {
                                            backgroundColor: theme.colors.orange[5],
                                        },
                                    })}>
                                        {i18n.t("edit")}
                                    </Button>
                                </form>

                            </Paper>

                        </motion.div>

                    </Grid.Col>
                    <Grid.Col>
                        <motion.div
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.7 }}
                        >

                        </motion.div>

                    </Grid.Col>
                </Grid>
            </SimpleGrid>
        </div>
    )
}

export default Account