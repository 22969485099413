import React, { createContext, useEffect, useState } from "react";

import "./App.css";
import Main from "./layouts/Main";
import Dashboard from "./pages/Dashboard";
import AuthContext from "./contexts/AuthContext";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Loader, MantineProvider } from "@mantine/core";
import Login from "./pages/Login/Login";
import { addItem, getItem } from "./utils/utilsApi";
import Account from "./pages/Account/Account";
import Security from "./pages/Security/Security";
import Settings from "./pages/Settings/Settings";
import jwt_decode from "jwt-decode";
import { getCompany, getProfile } from "./apis/user.api";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Company from "./pages/Company/Company";
import steps from "./utils/Steps";
import { demands } from "./apis/demands.api";
import Partner from "./pages/Partner/Partner";
import { Notifications } from "@mantine/notifications";
import { SpotlightProvider } from "@mantine/spotlight";
interface DataToken {
  iat: Number;
  exp: Number;
  roles: Array<string>;
  username: String;
}
const hostname = window.location.hostname;
const isHttps = window.location.protocol;
if (hostname !== "localhost" && isHttps !== "https:") window.location.replace("https://" + hostname)
function App() {
  const token: string | null = getItem("token");
  let isAuthenticated: boolean = token ? true : false;
  let dataToken: DataToken | null = token ? jwt_decode(token) : null;
  const username: String | undefined = dataToken?.username;
  const roles: Array<string> | undefined = dataToken?.roles
    ? dataToken?.roles
    : undefined;
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [user, setUser] = useState<Profile | undefined>(undefined);
  const [listDemands, setDemands] = useState<Demand[]>([]);
  const [loading, setLoading] = useState(false);

  let isAdmin;
  if (roles) {
    for (const admin of roles) {
      if (admin == "ROLE_COMPANY_ADMIN") {
        isAdmin = true;
      }
    }
  }
  useEffect(() => {
    getProfile().then((response) => {
      const { data }: any = response;
      setUser(data);
      setLoading(true);
    });

    if (isAuthenticated) {
      let getDatas: any;
      let getDatasCompany: any;
      const req = async () => {
        getDatas = await demands();
        getDatasCompany = await getCompany();
        setDemands(getDatas.data);
        setCompany(getDatasCompany.data);
      };
      req();
    }

  }, [isAuthenticated]);



  const sampleAppContext: AppContextInterface = {
    isAuthenticated: isAuthenticated,
    username: username,
    firstName: user?.firstname,
    lastName: user?.lastname,
    roles: roles,
    company: company,
    demands: listDemands,
    setDemands: setDemands,
  };

  return (
    <MantineProvider theme={{
      globalStyles: (theme) => ({
        '*, *::before, *::after': {
          boxSizing: 'border-box',
        },

        body: {
          
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
          color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
          lineHeight: theme.lineHeight,
        },

      }),
      colorScheme: 'light',
      headings: {
        // properties for all headings
        fontWeight: 400,
        fontFamily: 'Space Grotesk',
        // properties for individual headings, all of them are optional
        sizes: {
          h1: { fontWeight: 600, lineHeight: 1.4 },
          h2: { lineHeight: 1.5 },
          // ...up to h6
          h6: { fontWeight: 900 },
        },
      },
      fontFamily: 'Space Grotesk'
      
    }} withGlobalStyles withNormalizeCSS>
        <Notifications />
        <AuthContext.Provider value={sampleAppContext}>
          <Router>
            <div className="container-fluid">
              <Routes>
                {!isAuthenticated && <Route path="/login" element={<Login />} />}

                {!isAuthenticated && (
                  <Route path="/forgotPassword" element={<ForgotPassword />} />
                )}
                {isAuthenticated && (
                  <>
                    <Route
                      path="/dashboard"
                      element={
                        loading === true ? (
                          <Main children={<Dashboard demands={listDemands} />} />
                        ) : (
                          <Loader />
                        )
                      }
                    />
                    <Route
                      path="/company"
                      element={<Main children={<Company />} />}
                    />
                    <Route
                      path="/account"
                      element={<Main children={<Account />} />}
                    />
                    {isAdmin && (
                      <Route
                        path="/partner"
                        element={<Main children={<Partner />} />}
                      />)}
                    <Route
                      path="/security"
                      element={<Main children={<Security />} />}
                    />
                    <Route
                      path="/settings"
                      element={<Main children={<Settings />} />}
                    />
                  </>
                )}
                <Route
                  path="*"
                  element={
                    <Navigate to={isAuthenticated ? "/dashboard" : "/login"} />
                  }
                />
              </Routes>
            </div>
          </Router>
        </AuthContext.Provider>
    </MantineProvider >

  );
}

export default App;
