import { Button, TextInput,FileButton, Group, Text, Divider  } from '@mantine/core';
import { useForm } from '@mantine/form';
import { addPartner } from '../../apis/partner.api';
import { useState } from 'react';
import axios from 'axios';
import i18n from '../../i18n';

interface Props {
  action: any;
}

export default function PartnerForm(props : Props) {

      const uploadFile  = async (logo: File | null) => {
   
        if(!logo) return;
        const formData = new FormData()
        formData.append('file', logo)
        const result = await axios.post('https://api.unitix.io/uploadFile', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return Promise.resolve(result.data);

      }
  
      const [file, setFile] = useState<File | null>(null);
      const form = useForm({
          initialValues: {
              name: '',
              mail: ''
          },
      
          validate: {
            mail: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
          },
        });
    

  return (
    <form onSubmit={form.onSubmit((values) => {
        uploadFile(file).then((result) => {
          
          addPartner({
            name: values.name, 
            mail: values.mail, 
            logo: result.file.url
          }).then(() => {
            props.action()
          })
          
        })        
    })}>
      <TextInput
        label={i18n.t("Name")}
        placeholder="Entrez votre nom"
        required
        value={form.values.name}
        onChange={(event) => form.setFieldValue('name', event.currentTarget.value)}
      />
      <TextInput
        label={i18n.t("AdresseMail")}
        placeholder="Entrez votre email"
        required
        type="email"
        value={form.values.mail}
        onChange={(event) => form.setFieldValue('mail', event.currentTarget.value)}
      />
        <Divider my="xs" label="Ajouter un logo" labelPosition="left" />
        <FileButton onChange={setFile} accept="image/png,image/jpeg">
          {(props) => <Button {...props}>{i18n.t("LabelUploadLogo")}</Button>}
        </FileButton>
        {file && (
          <Text size="sm" align="center" mt="sm">
            Nom du fichier: {file.name}
          </Text>
        )}

      <Button fullWidth style={{
            marginTop: "20px",
      }} type="submit">{i18n.t("submit")}</Button>
    </form>
  );
}
